<template>
  <div class="footer">
    <div class="logo">
      <div class="qrcode">
        <img src="../assets/serviceqrcode.png" />
        <span>U2官方旗舰店</span>
      </div>
      <div class="title">
        <img src="../assets/logo.png" />
        <span>不同水质 不同配方</span>
      </div>
      <div class="menu">
        <p><router-link to="home">首页 一</router-link></p>
        <p><router-link to="case">产品展示</router-link></p>
        <p><router-link to="solution">品牌介绍</router-link></p>
        <p><router-link to="about">联系我们</router-link></p>
      </div>
    </div>

    <div class="linestyle"></div>

    <div class="copyright">
      <div class="left">
        <span style="margin-top: 0.1rem;">
          Copyright © 2019, youtuqingjieyongpin Co., Ltd. All Rights Reserved.
        </span>
        <span style="margin-bottom: 0.1rem;">
          浙江U2清洁用品有限公司版权所有 浙ICP备12003180号
        </span>
      </div>
      <span class="right">
        源自法国 始于1936
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goMap() {
      window.open("https://j.map.baidu.com/e2/Por");
    },
    goBeian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010555"
      );
    },
    goICP() {
      window.open("http://beian.miit.gov.cn");
    }
  }
};
</script>

<style lang="scss">
.footer {
  position: relative;
  height: 2.2rem;
  color: #212121;
  font-size: 0.08rem;
  padding: 0 2rem;
  background: #f7f7f7;
  .logo {
    position: relative;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    .qrcode {
      width: 15%;
      margin-top: 3%;
      text-align: center;

      img {
        width: 100%;
      }

      span {
        width: 100%;
      }
    }

    .title {
      width: 70%;
      text-align: center;
      margin-top: 2%;
      // margin-left: 10%;
      line-height: 1rem;
      color: #dfb25f;
      position: relative;
      display: inline;
      // margin-left: 9%;

      img {
        vertical-align: middle;
        width: 0.5rem;
        top: 0.2rem;
        position: absolute;
        left: 33%;
      }

      span {
        vertical-align: middle;
        margin-left: 15%;
      }
    }

    .menu {
      width: 15%;
      text-align: center;
      margin-top: 3%;
      a {
        font-size: 0.08rem;
        height: 12%;
        color: #212121;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: #dfb25f;
        }
      }
    }
  }

  .copyright {
    position: relative;
    height: 30%;
    display: flex;
    line-height: 0.54rem;
    text-align: center;

    .left {
      width: 70%;
      text-align: left;
      display: grid;
      line-height: 0.15rem;
    }

    .right {
      width: 30%;
      text-align: right;
      font-size: 0.12rem;
    }
  }

  .linestyle {
    height: 1px;
    background: #e6e6e6;
  }
}
</style>
